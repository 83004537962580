import { CacheLocation } from 'msal';

import { AppConstants } from '../app/app-constants';

export const environment = {
  production: true,
  appName: 'Admin',
  title: 'Admin Portal',
  environmentName: 'GDHV',
  apiBaseUrl: 'https://app-adminportalwebapi-gdhv.azurewebsites.net/api/',
  simulatorApiBaseUrl: 'https://app-websimulator-gdhv.azurewebsites.net',
  useMockComponents: false,
  authConfig: {
    auth: {
      clientId: '56d58dde-db74-4512-9cf5-f55fd134facd',
      authority: 'https://gdhvb2c.b2clogin.com/tfp/gdhvb2c.onmicrosoft.com/B2C_1_AdminPortal_SignIn_SignUp',
      redirectUri: 'https://adminportal.gdhv-iot.com/',
      postLogoutRedirectUri: 'https://adminportal.gdhv-iot.com/',
      navigateToLoginRequestUrl: true,
      validateAuthority: true,
      knownAuthorities: ['https://gdhvb2c.b2clogin.com/tfp/gdhvb2c.onmicrosoft.com/B2C_1_AdminPortal_SignIn_SignUp', 
        'https://gdhvb2c.b2clogin.com/tfp/gdhvb2c.onmicrosoft.com/B2C_1_AdminPortal_PasswordReset'] 
    },
    cache: {
      cacheLocation: ('localStorage' as CacheLocation),
      storeAuthStateInCookie: AppConstants.isIE,
    },
  },
  msalConfig: {
    popUp: false,
    consentScopes: [
      'https://gdhvb2c.onmicrosoft.com/adminwebapigdhv/read'
    ],
    unprotectedResources: [],
    protectedResourceMap: ([
      ['https://app-adminportalwebapi-gdhv.azurewebsites.net/', ['https://gdhvb2c.onmicrosoft.com/adminwebapigdhv/read']]
    ] as [string, string[]][]),
    extraQueryParameters: {}
  },
  b2cPolicies: {
    authorities: {
      signUpSignIn: {
        name: 'B2C_1_AdminPortal_SignIn_SignUp',
        authority: 'https://gdhvb2c.b2clogin.com/tfp/gdhvb2c.onmicrosoft.com/B2C_1_AdminPortal_SignIn_SignUp'
      },
      passwordReset: {
        name: 'B2C_1_AdminPortal_PasswordReset',
        authority: 'https://gdhvb2c.b2clogin.com/tfp/gdhvb2c.onmicrosoft.com/B2C_1_AdminPortal_PasswordReset'
      },
    },
  },
  map: {
    urlTemplate: 'https://tile.openstreetmap.org/level/tileX/tileY.png'
  },
  appInsights: {
    instrumentationKey: 'e3030919-9c7b-4406-ae13-1520c6f294ce',
    connectionString: 'InstrumentationKey=e3030919-9c7b-4406-ae13-1520c6f294ce'
  },
  products: {
    dimplexWifi: '1FC4EEC5-628E-4195-ACE9-588FD031A679',
    intuisOfrWifi: 'B942990D-FF3F-4BC8-B3BE-F1575F1FFF1E',
    mullerPhWifi: '28B98C91-A758-4CEF-ADCE-A62214CEDBDA',
    optiflameV2: '99079A0B-EC8E-4284-B132-E4EDCF09069F',
    optiflameV3: 'C629B1B1-D6CD-4987-A243-126F7D06E106',
    optiflameV4: 'F1395F22-15DA-46AD-9EDC-F0809708D085'
  },
  reporting: {
    dashboardUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={dc787174-6776-411e-a35e-3e1700799cc9}&action=embedview&Item='Dashboard'!A1%3AAB38&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
    firmwareUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={dc787174-6776-411e-a35e-3e1700799cc9}&action=embedview&Item='Firmware%20Stats'!M3%3AAC21&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
    totalVsOnlineHubsUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={dc787174-6776-411e-a35e-3e1700799cc9}&action=embedview&Item=Total_Vs_Online_Hubs&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
    totalVsOnlineAppliancesUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={dc787174-6776-411e-a35e-3e1700799cc9}&action=embedview&Item=Total_Vs_Online_Appliances&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
    wiFiAppliancesUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={dc787174-6776-411e-a35e-3e1700799cc9}&action=embedview&Item=WiFi_Appliances&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
    dsmHubsUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={dc787174-6776-411e-a35e-3e1700799cc9}&action=embedview&Item=DSM_Hubs&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
    applianceTypesUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={dc787174-6776-411e-a35e-3e1700799cc9}&action=embedview&Item=Appliance_Types&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
    bleAppliancesUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={dc787174-6776-411e-a35e-3e1700799cc9}&action=embedview&Item=BLE_Appliances&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
    userRegistrationsUrl: "https://glendimplex.sharepoint.com/sites/GroupIoTTeam/_layouts/15/Doc.aspx?sourcedoc={dc787174-6776-411e-a35e-3e1700799cc9}&action=embedview&Item=User_Registrations&wdHideGridlines=True&wdInConfigurator=True&wdAllowInteractivity=True",
  }
};
